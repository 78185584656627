import { useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { getMessages } from "../utils/util";
import { useSnackbar, VariantType } from "notistack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

type UseApiType = {
  url: any; //string o función de parametros que devuelve string
  method: "GET" | "POST" | "PUT" | "DELETE";
  setMessageSnackbar: SetMessageSnackbarType;
  setSeverity: SetSeverityType;
  showSnackbar: ShowSnackbarType;
  messages?: Messages;
  body?: { [key: string]: any } | any[];
  onSuccess?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
  extraConfig?: { [key: string]: any };
};

type SetMessageSnackbarType = (message: string) => void;
type SetSeverityType = (
  severity: "success" | "error" | "warning" | "info"
) => void;
type ShowSnackbarType = () => void;

export type Messages = {
  [key: number]: string;
} & {
  200?: string;
  201?: string;
  204?: string;
  400?: string;
  401?: string;
  403?: string;
  404?: string;
  500?: string;
};

type Status = 200 | 201 | 204 | 400 | 401 | 403 | 404 | 500;

const useApi = (
  url: any,
  method: "GET" | "POST" | "PUT" | "DELETE",
  messages?: Messages,
  body?: { [key: string]: any } | any[],
  onSuccess?: (...args: any[]) => any,
  onError?: any,
  hasSuccessSnackbar?: boolean,
  controller?: any,
  downloadFile?: boolean,
  extraConfig?: { [key: string]: any }
) => {
  const axiosPrivate = useAxiosPrivate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<Status | undefined>(undefined);

  const handleClickVariant = (message: string, variant: VariantType) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, {
      variant,
      action: (key) => (
        <IconButton
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          <CloseIcon sx={{ color: "white" }} />
        </IconButton>
      ),
    });
  };

  const handleResponse = (status: Status, data: any, extraData?: any) => {
    setStatus(status);
    if (status >= 200 && status < 300) {
      setError(null);
      setData(data);
      hasSuccessSnackbar !== undefined
        ? hasSuccessSnackbar &&
          handleClickVariant(
            (messages && getMessages(messages)[status]) || "Operación exitosa",
            "success"
          )
        : handleClickVariant(
            (messages && getMessages(messages)[status]) || "Operación exitosa",
            "success"
          );
      if (downloadFile) {
        const url = window.URL.createObjectURL(
          new Blob([data], { type: extraData.fileType })
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = extraData.fileName;
        document.body.appendChild(link);
        link.click();
      }
      onSuccess && onSuccess(data, extraData);
      setIsLoading(false);
    } else if (status === undefined) {
      setIsLoading(false);
      setError(data);
    } else {
      setError(data || true);
      setIsLoading(false);
      onError && onError(data);

      handleClickVariant(
       data.mensaje ? data.mensaje : (messages && getMessages(messages)[status]) ||
          ("Hubo un error, intentelo más tarde."),
        "error"
      );
    }
  };
  const callApi = async (
    alternativeURL?: string,
    alternativeBody?: any,
    extraData?: any
  ) => {
    try {
      setIsLoading(true);
      switch (method) {
        case "GET":
          if (downloadFile) {
            const response = await axiosPrivate.get(
              alternativeURL ? alternativeURL : url,
              {
                responseType: "blob",
                ...extraConfig,
              }
            );
            handleResponse(response.status as Status, response.data, extraData);
          } else {
            const response = await axiosPrivate.get(
              alternativeURL ? alternativeURL : url,
              controller && { signal: controller.signal, ...extraConfig }
            );
            handleResponse(response.status as Status, response.data);
          }
          break;
        case "POST":
          if (body && !alternativeBody) {
            const bodyJSON = JSON.stringify(body);
            if (downloadFile) {
              console.log("entra aca?");
              
              const responsePost = await axiosPrivate.post(
                alternativeURL ? alternativeURL : url,
                bodyJSON,
                {
                  responseType: "blob",
                  ...extraConfig,
                }
              );
              handleResponse(
                responsePost.status as Status,
                responsePost.data,
                extraConfig
              );
            } else {
              console.log("entra aca 3?");
              const responsePost = await axiosPrivate.post(
                alternativeURL ? alternativeURL : url,
                bodyJSON,
                controller
                  ? { signal: controller.signal, ...extraConfig }
                  : extraConfig
              );
              handleResponse(
                responsePost.status as Status,
                responsePost.data,
                extraData
              );
            }
          } else if (alternativeBody) {
            console.log("entra aca 2?");
            const alternativeBodyJSON = JSON.stringify(alternativeBody);
            const responsePost = await axiosPrivate.post(
              alternativeURL ? alternativeURL : url,
              alternativeBodyJSON,
              controller
                ? { signal: controller.signal, ...extraConfig }
                : extraConfig
            );
            handleResponse(
              responsePost.status as Status,
              responsePost.data,
              extraData
            );
          } else {
            console.log("entra aca 5?");
            const responsePost = await axiosPrivate.post(
              url,
              undefined,
              controller
                ? { signal: controller.signal, ...extraConfig }
                : extraConfig
            );
            handleResponse(
              responsePost.status as Status,
              responsePost.data,
              extraData
            );
          }
          break;
        case "PUT":
          if (body && !alternativeBody) {
            const dataPut = JSON.stringify(body);
            const responsePut = await axiosPrivate.put(
              alternativeURL ? alternativeURL : url,
              dataPut,
              extraConfig
            );
            handleResponse(responsePut.status as Status, responsePut.data);
          } else if (alternativeBody) {
            const alternativeBodyJSON = JSON.stringify(alternativeBody);
            const responsePut = await axiosPrivate.put(
              alternativeURL ? alternativeURL : url,
              alternativeBodyJSON,
              extraConfig
            );
            handleResponse(responsePut.status as Status, responsePut.data);
          } else if (!body && !alternativeBody) {
            const responsePut = await axiosPrivate.put(
              alternativeURL ? alternativeURL : url
            );
            handleResponse(responsePut.status as Status, responsePut.data);
          } else {
            handleResponse(400, "No hay información para enviar.");
          }
          break;
        case "DELETE":
          if (body && !alternativeBody) {
            const bodyJSON = JSON.stringify(body);
            const responseDelete = await axiosPrivate.delete(
              alternativeURL ? alternativeURL : url,
              { data: bodyJSON }
            );
            handleResponse(
              responseDelete.status as Status,
              responseDelete.data,
              extraData
            );
          } else if (alternativeBody) {
            const alternativeBodyJSON = JSON.stringify(alternativeBody);
            const responseDelete = await axiosPrivate.delete(
              alternativeURL ? alternativeURL : url,
              { data: alternativeBodyJSON }
            );
            handleResponse(
              responseDelete.status as Status,
              responseDelete.data,
              extraData
            );
          } else {
            const responseDelete = await axiosPrivate.delete(  alternativeURL ? alternativeURL : url);
            handleResponse(
              responseDelete.status as Status,
              responseDelete.data,
              extraData
            );
          }
          break;
        default:
          break;
      }
    } catch (error: any) {
      console.log(error)
      if (error.message.startsWith("timeout")) {
        handleResponse(500, error?.data);
      }
      if (error.message !== "canceled") {
        handleResponse(error?.request?.status as Status, error?.response.data);
      }
    }
  };

  return { data, error, isLoading, callApi, setData, status };
};

export default useApi;
