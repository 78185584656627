import { Stack, Typography } from "@mui/material";
import Button from "../buttons/Button";
import errorImage from "../../assets/ErrorBoundaryImage.png"

const ErrorBoundary = ({ error, resetErrorBoundary }: any) => {

   const handleRedirect = () => {
        window.location.href = "/"; // Redirige usando window.location
      };

  return (
    <Stack
          sx={{
            height: "100vh",
            width: "100%",
            backgroundColor: "var(--very-very-light-blue)",
          }}
        >
          <img
            style={{ width: "30%", marginLeft: "auto", marginRight: "auto" }}
            src={errorImage}
            alt="logo"
          />
          <Typography
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              color: "var(--dark-blue)",
              fontWeight: "bold",
              fontSize: "36px",
            }}
          >
            Algo salió mal.
          </Typography>
          <Typography
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
              color: "var(--dark-blue)",
              fontWeight: "bold",
              fontSize: "28px",
            }}
          >
            Contáctese con los desarrolladores.
          </Typography>
          <Stack
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Button
              title="Volver al inicio"
              color="blue"
              type="button"
              onClick={handleRedirect}
            />
          </Stack>
        </Stack>
  );
};

export default ErrorBoundary;