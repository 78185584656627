import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { AuthProvider } from "./context/AuthProvider";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./styles/material-theme";
import App from "./App.tsx";
import {disableReactDevTools} from "@fvilers/disable-react-devtools";
import "./index.css";

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <AuthProvider>
          <Routes>
            <Route path="*" element={<App />} />
          </Routes>
        </AuthProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  </BrowserRouter>
, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
